import {Alert, Grid} from "@mui/material";
import React from "react";
import successImage from '../icons/successfully-done.gif'
import {useParams} from "react-router-dom";

export const SuccessForPayment = () => {

    const { paymentId } = useParams();

    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{ minHeight: '100vh' }}
        >
            <Grid item xs={12}>
                <img src={successImage} width='256px' />
            </Grid>
            <Grid item xs={12}>
                <Alert severity="success">Twoja płatność została pomyślnie przetworzona.</Alert>
            </Grid>
        </Grid>
    )
}